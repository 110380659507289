<template>
    <div class="identify-index">
        <TableListPageTemplate v-bind="templateConfig" :pagingConfig.sync="getIdentifyListParams" :totalCount="totalCount">
            <template #tableOperate="{ item }">
                <el-link type="primary" :underline="false" @click="copy(item)" class="copy">分享鉴定点</el-link>
                <el-link type="primary" :underline="false" @click="allotClick(item, 1)">分配理论题</el-link>
                <el-link type="primary" :underline="false" @click="allotClick(item, 2)">分配实操题</el-link>
                <el-link type="primary" :underline="false" @click="allotClick(item, 3)">分配理论题试卷</el-link>
                <el-link type="primary" :underline="false" @click="allotClick(item, 4)">分配实操题试卷</el-link>
                <el-link type="success" :underline="false" @click="editIdentify(item)">编辑</el-link>
                <el-link type="danger" :underline="false" @click="deleteIdentify(item)">删除</el-link>
            </template>
        </TableListPageTemplate>
        <Dialog ref="allot" :title="dialogTitle" :confirmHandler="confirmAssign" :width="850">
            <div>
                <el-cascader-panel :props="allotProps" v-model="allocationValue" :options="cascaderData"></el-cascader-panel>
            </div>
        </Dialog>
        <Dialog
            ref="addIdentify"
            :title="dialogCTitle"
            :confirmHandler="confirmAddIdentifyCenter"
            :cancelHandler="clearForm"
            :width="550"
        >
            <div class="add-identify-center-dialog">
                <div class="input-title">鉴定点名称</div>
                <el-input v-model="operateIdentifyParams.name" minlength="3" maxlength="20"></el-input>
                <div class="input-title">LOGO</div>
                <div class="logo-box" v-if="operateIdentifyParams.avatar">
                    <img :src="operateIdentifyParams.avatar" alt="" />
                    <i @click="clearLogo" class="iconfont">&#xe651;</i>
                </div>
                <el-upload
                    v-else
                    class="logo-uploader"
                    action="school/upload"
                    :headers="headersParams"
                    name="avatar"
                    :show-file-list="false"
                    :on-success="handleCoverSuccess"
                    :before-upload="beforeCoverUpload"
                >
                    <i class="el-icon-plus logo-uploader-icon"></i>
                </el-upload>
            </div>
        </Dialog>
        <Dialog
            ref="deleteIdentify"
            title="删除鉴定中心"
            :confirmHandler="confirmDeleteIdentify"
            :cancelHandler="clearForm"
            themeColor="28, 176, 100"
        >
            <div class="">删除后不可恢复</div>
        </Dialog>
    </div>
</template>

<script>
import Dialog from "components/admin/common/Dialog";
import TableListPageTemplate from "components/admin/common/TableListPageTemplate";

import {
    getActualCateData, //实操题
    getIdentifyCenterCateList, //理论题
    getIdentifyCenterActualPaperCascade, //实操题试卷
    getIdentifyCenterTheoryPaperCascade, //理论题试卷
    identifyCenterAssignTheory,
    identifyCenterAssignActual,
    identifyCenterAssignPaper,
    getIdentifyCenterAssignTheoryQuestionList,
    getIdentifyCenterAssignActualQuestionList,
    getIdentifyCenterAssignPaperList,
    IdentifyCenterCreate,
    IdentifyCenterList,
    IdentifyCenterDelete,
} from "@/utils/apis";
import ClipboardJS from "clipboard";

export default {
    name: "Index",
    data() {
        return {
            //cascader
            isShowCascader: true,
            cascaderDataList: [], //依次为：理论题，实操题，理论题试卷，实操题试卷
            allotProps: { value: "id", label: "name", multiple: true },
            allocationValue: [],
            dialogSourceMark: 3,
            assignId: "",

            //upload
            headersParams: {
                Authorization: localStorage.getItem("adminToken") || "",
            },

            operateIdentifyParams: {
                name: "",
                avatar: "",
                id: "", //编辑时传
            },
            getIdentifyListParams: {
                paging: "1",
                page: 1,
                pageSize: 10,
            },
            totalCount: -1,
            tableData: [],
            isEdit: false
        };
    },
    components: {
        Dialog,
        TableListPageTemplate,
    },
    computed: {
        templateConfig() {
            return {
                tableData: this.tableData,
                rightConfig: {
                    text: "添加鉴定中心",
                    handler: this.addIdentifyCenter,
                },
                tableConfig: [
                    { label: "账号名称", prop: "name" },
                    { label: "logo", prop: "avatar", width: "150", isImg: true },
                    { label: "创建时间", prop: "create_time", align: "center" },
                    { label: "操作", align: "center", width: 650 },
                ],
            };
        },
        dialogTitle() {
            let arr = ["", "分配理论题", "分配实操题", "分配理论题试卷", "分配实操题试卷"];
            return arr[this.dialogSourceMark];
        },
        cascaderData() {
            return this.cascaderDataList[this.dialogSourceMark - 1];
        },
        dialogCTitle() {
            return this.isEdit ? '编辑鉴定中心' : '添加鉴定中心';
        }
    },
    watch: {
        "getIdentifyListParams.page"() {
            this.getIdentifyList();
        },
    },
    mounted() {
        this.getCascaderData();
        this.getIdentifyList();
    },
    methods: {
        /**获取级联的数据 */
        async getCascaderData() {
            let setData = (res) => this.verifySendResCode(res) && this.cascaderDataList.push(res.data);
            let res = await getIdentifyCenterCateList({ type: 3 });
            this.deleteEmptyCate(res.data);
            setData(res);
            res = await getActualCateData();
            setData(res);
            res = await getIdentifyCenterTheoryPaperCascade();
            setData(res);
            res = await getIdentifyCenterActualPaperCascade();
            setData(res);
        },
        deleteEmptyCate(arr) {
            arr.forEach((item) => {
                item.children.length > 0 ? this.deleteEmptyCate(item.children) : this.$delete(item, "children");
            });
        },
        /**删除鉴定中心，打开dialog */
        deleteIdentify(item) {
            this.operateIdentifyParams.id = item.id;
            this.$refs.deleteIdentify.open();
        },
        /**确认删除 */
        async confirmDeleteIdentify() {
            let res = await IdentifyCenterDelete({
                id: this.operateIdentifyParams.id,
            });
            if (res.code == 200) {
                this.$refs.deleteIdentify.close();
                this.$message.success(res.msg);
                this.getIdentifyList();
            } else {
                this.$message.error(res.msg);
            }
        },
        /**打开添加鉴定中心模态框 */
        addIdentifyCenter() {
            this.dialogSourceMark = 1;
            this.$refs.addIdentify.open();
        },
        /**确认添加 */
        async confirmAddIdentifyCenter() {
            let res = await IdentifyCenterCreate(this.operateIdentifyParams);
            if (res.code == 200) {
                this.$message.success(res.msg);
                this.$refs.addIdentify.close();
                this.getIdentifyList();
            } else {
                this.$message.error(res.msg);
            }
        },
        /**验证请求是否成功 */
        verifySendResCode(res) {
            let isSucceed = res.code == 200;
            !isSucceed && this.$messgae.error(res.msg);
            return isSucceed;
        },
        /**点击分配按钮记录标识打开dialog */
        async allotClick(item, mark) {
            let res;
            this.$refs.allot.open();
            this.dialogSourceMark = mark;
            this.assignId = item.id;
            if (mark == 1) {
                res = await getIdentifyCenterAssignTheoryQuestionList({ check_center_id: item.id });
            } else if (mark == 2) {
                res = await getIdentifyCenterAssignActualQuestionList({ check_center_id: item.id });
            } else if (mark == 3 || mark == 4) {
                let type = mark == 3 ? 1 : 2;
                res = await getIdentifyCenterAssignPaperList({ check_center_id: item.id, paper_type: type });
            }
            if (this.verifySendResCode(res)) {
                this.allocationValue = res.data;
            }
            this.$refs.allot.open();
        },
        /**确认分配 */
        async confirmAssign() {
            let { assignId, allocationValue } = this;
            let ids = this.allocationValue
                .map((item) => item[item.length - 1])
                .flat()
                .join(",");
            let res;
            if (this.dialogSourceMark == 1) {
                res = await identifyCenterAssignTheory({
                    check_center_id: assignId,
                    category_id: ids,
                });
            } else if (this.dialogSourceMark == 2) {
                res = await identifyCenterAssignActual({
                    check_center_id: assignId,
                    category_id: ids,
                });
            } else if (this.dialogSourceMark == 3 || this.dialogSourceMark == 4) {
                let type = this.dialogSourceMark == 3 ? 1 : 2;
                res = await identifyCenterAssignPaper({
                    check_center_id: assignId,
                    paper_type: type,
                    paper_ids: ids,
                });
            }
            if (this.verifySendResCode(res)) {
                this.$message.success(res.msg);
                this.$refs.allot.close();
            }
        },
        copy(item) {
            let host = localStorage.getItem("hosturl");
            let clipboard = new ClipboardJS(".copy", {
                text(elem) {
                    return `${host}apply?id=${item.id}`;
                },
            });
            clipboard.on("success", (e) => {
                this.$message({
                    type: "success",
                    message: "复制成功",
                    duration: 1000,
                    onClose: () => {
                        clipboard.destroy();
                    },
                });
            });
        },
        /**获取鉴定中心的列表 */
        async getIdentifyList() {
            let res = await IdentifyCenterList(this.getIdentifyListParams);
            if (res.code == 200) {
                this.tableData = res.data.list;
                this.totalCount = res.data.total;
            } else {
                this.$message.error(res.msg);
            }
        },
        /**编辑 */
        editIdentify(item) {
            this.isEdit = true;
            this.operateIdentifyParams.id = item.id;
            this.operateIdentifyParams.name = item.name;
            this.operateIdentifyParams.avatar = item.avatar;
            this.addIdentifyCenter(1);
        },
        /**开始上传 */
        handleCoverSuccess(res, file) {
            if (res.code === 200) {
                console.log();
                this.operateIdentifyParams.avatar = res.data.src; // 有域名
                this.$message.success("头像上传成功");
            } 
            // else {
            //     this.$message.error("头像上传失败，请稍后再试~");
            // }
        },
        /**验证图片格式 */
        beforeCoverUpload(file) {
            const isLt2M = file.size/1024/1024 <2
            const isImg =
                file.name.toLowerCase().endsWith(".jpg") ||
                file.name.toLowerCase().endsWith(".jpeg") ||
                file.name.toLowerCase().endsWith(".png");
            if (!isImg) {
                this.$message.error("上传图片只能是 jpg 或 jpeg 或 png 格式!");
                return false;
            }else if(!isLt2M){
                  this.$message.error("上传图片大小不能超过2M！");
            }
            return isImg;
        },
        /**点击×删除头像地址 */
        clearLogo() {
            this.operateIdentifyParams.avatar = "";
        },
        clearForm() {
            this.isEdit = false;
            this.clearLogo();
            this.operateIdentifyParams.name = "";
            this.operateIdentifyParams.id = "";
        },
    },
};
</script>

<style scoped lang="scss">
.identify-index {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 20px;
    .paging-box {
        padding: 20px;
        text-align: center;
    }
    .identify-center-table {
        flex: 1;
        // margin-bottom: 20px;
        overflow: hidden;
    }
    .add-identify-center {
        text-align: end;
        padding-bottom: 20px;
    }
    .add-identify-center-dialog {
        font-size: 16px;
        .input-title {
            margin: 10px 0;
            font-size: 16px;
            text-align: start;
            color: #333;
        }
        .input-title:nth-child(1) {
            margin-top: 0;
        }
        .logo-box {
            position: relative;
            width: 80px;
            height: 80px;
            box-sizing: border-box;
            border: 1px solid #eeeeee;

            img {
                width: 100%;
                height: 100%;
            }

            i {
                line-height: 1;
                font-size: 14px;
                color: #dcdcdc;
                position: absolute;
                right: -20px;
                top: 0;
                cursor: pointer;
            }
        }
        .logo-uploader {
            ::v-deep.el-upload {
                width: 80px;
                height: 80px;
                background: #eeeeee;
                display: flex;
                align-items: center;
                justify-content: center;

                .logo-uploader-icon {
                    font-size: 32px;
                    color: #a0a0a0;
                }
            }
        }
    }
}
.allot-class,
.allot-class-two {
    &.allot-class-two .el-cascader-menu {
        width: 50%;

        &:last-of-type {
            border-right: none;
        }
    }

    &.allot-class .el-cascader-menu {
        flex: 1;
        // width: calc(100% / 3);

        &:nth-child(3) {
            border-right: none;
        }
    }

    .el-cascader-menu {
        border-right: 1px solid #e4e7ed;
    }
}
::v-deep .el-cascader-menu__wrap {
    height: 300px;
    padding-bottom: 20px;
    .el-cascader-menu__list {
        .el-cascader-node {
            &:hover {
                background: #e7f6ef;
            }
        }
    }
}
::v-deep .el-cascader-menu {
    flex: 1;
    min-width: 0;
}
::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}
// .cascade-width {
//     width: 100% !important;
//     ::v-deep .el-cascader-menu {
//         width: 100% !important;
//     }
// }
</style>
